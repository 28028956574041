/**======================================================================
=========================================================================
Template Name: DashboardKit - Most Complete Bootstrap Admin Template
Author: DashboardKit
Support: dashboardkit[at]gmail[dot]com
File: style.css
=========================================================================
=================================================================================== */
// theme font
// main framework
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "settings/dark/custom-variables";
@import "settings/dark/theme-variables";
@import "node_modules/bootstrap/scss/bootstrap"; // main framework
@import "themes/generic";
@import "themes/general"; // Layouts
@import "themes/layouts/pc-sidebar";
@import "themes/layouts/pc-header";
@import "themes/layouts/pc-common"; // widget
@import "themes/components/widget"; // Componants
@import "themes/components/avtar";
@import "themes/components/badge";
@import "themes/components/button";
@import "themes/components/dropdown";
@import "themes/components/card";
@import "themes/components/progress";
@import "themes/components/table";
@import "themes/components/tabs";
@import "themes/components/form"; // pages
@import "themes/pages/authentication";
@import "themes/pages/maintaince";
@import "themes/pages/pages";
@import "themes/pages/help-desk";
@import "themes/pages/fullcalender";
@import "themes/pages/icon-lauouts";
@import "themes/pages/user";
@import "themes/pages/e-commerce";
// custom pluginss
@import "themes/plugins/sweatalert";
@import "themes/plugins/lightbox";
@import "themes/plugins/notification";
@import "themes/plugins/pnotify";
@import "themes/plugins/bar-rating";
@import "themes/plugins/rangeslider";
@import "themes/plugins/daterange";
@import "themes/plugins/tags-input";
@import "themes/plugins/maxlength";
@import "themes/plugins/wizard";
@import "themes/plugins/select2";
@import "themes/plugins/data-tables";
@import "themes/plugins/peitychart";

text {
    fill: $body-color;
}

.apexcharts-legend-text {
    color: $body-color !important;
}

.apexcharts-xaxis line {
    stroke: $border-color !important;
}

.apexcharts-gridline,
.apexcharts-xaxis-tick {
    stroke: $border-color;
}

.pc-header {
    .pc-head-link {
        &.active,
        &:active,
        &:focus,
        &:hover {
            background: rgba(255, 255, 255, 0.15);
            color: #fff;
        }
    }
}

.card .dropzone {
    background: darken($card-bg,5%);
}

.bd-example-modal,
.invoice-total.table {
    background: $body-bg;
}

.task-board-left .task-right-header-revision,
.task-board-left .task-right-header-status,
.task-board-left .task-right-header-users {
    border-color: $border-color;
}

#task-container li,
.fc-button,
.fc-today-button {
    border-color: $border-color;
    background: darken($card-bg,5%);
    color: $body-color;
}

.fc-state-active,
.fc-state-disabled,
.fc-state-down {
    background: $primary;
    color: $white;
}

.filter-bar .navbar,
.sticky-action .card-header {
    background: $card-bg;
}

.border-light {
    border-color: $border-color !important;
}

.auth-wrapper .auth-side-form,
.auth-wrapper .saprator span,
.pc-wizard-details .nav.nav-tabs .nav-link.active,
.pc-wizard-details .nav.nav-tabs .show > .nav-link,
.sw-theme-default .step-content,
table.DTFC_Cloned tr {
    background: $card-bg;
}

.pct-customizer {
    .pct-c-content {
        color: #293240;

        h6 {
            color: #060606;
        }

        .custom-control-label::before {
            background: #fff;
            border-color: #adb5bd;
        }

        .custom-switch .custom-control-label::after {
            background: #adb5bd;
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            border-color: #7267EF;
            background-color: #7267EF;
        }

        .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
            background: #fff;
        }
    }
}

.card-footer.bg-light {
    background: darken($card-bg,3%) !important;
}

.dropdown .dropdown-item svg {
    fill: rgba(155, 158, 162, .11);
}

.tab-wizard {
    &.nav {
        .nav-item {
            .nav-link {
                background: transparentize($card-bg,.6);
            }
        }

        .nav-link.active,
        .show > .nav-link {
            background: $card-bg;
        }
    }
}

.text-dark {
    color: #d9d9da !important;
}

.uppy-Dashboard-inner,
.uppy-DashboardTab,
.uppy-DragDrop-container {
    background: $card-bg;
    border-color: $border-color;
}

.auth-wrapper.auth-v3 {
    background: linear-gradient(to right, $primary 50%, $card-bg 0);
}

.tox .tox-tbtn {
    color: $body-color;
}

.tox .tox-menubar,
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
    background: $card-bg;
}

.ql-snow .ql-stroke {
    stroke: $body-color;
}

.ql-snow .ql-picker {
    color: $body-color;
}

.ql-container.ql-snow,
.ql-toolbar.ql-snow,
.trumbowyg-box,
.trumbowyg-editor {
    border-color: $border-color;
}

.note-toolbar.card-header {
    background: #fff;
    border-bottom-color: #ddd;
}

.trumbowyg-button-pane {
    background: $card-bg !important;
}

.trumbowyg-button-pane button.trumbowyg-active,
.trumbowyg-button-pane button:not(.trumbowyg-disable):focus,
.trumbowyg-button-pane button:not(.trumbowyg-disable):hover {
    background: darken($card-bg, 5%) !important;
}

.trumbowyg-box svg,
.trumbowyg-modal svg {
    fill: $body-color !important;
    color: $body-color;
}

.trumbowyg-button-pane .trumbowyg-button-group::after {
    background: $border-color !important;
}

.trumbowyg-button-pane::after {
    background: $border-color !important;
}

.trumbowyg-button-pane {
    border-bottom: 1px solid $border-color !important;
}

button:focus {
    outline: none;
}

.trumbowyg-box,
.trumbowyg-editor {
    border-color: $border-color !important;
}

.pc-header {
    box-shadow: 0 1px 0 0 #000000;
}
@each $color, $value in $theme-colors {
    .badge.bg-light-#{$color} {
        background: transparentize($value, 0.8);
        color: $value;
        border-color: transparentize($value, 0.8);
    }

    .icon-svg-#{$color} {
        fill: transparentize($value, 0.8);
        stroke: $value;
    }

    .btn-light-#{$color} {
        background: transparentize($value, 0.8);
        border-color: transparentize($value, 0.8);
    }
}

.latest-update-card .card-body .latest-update-box:after {
    background: darken($card-bg, 5%);
}

*[class*="bg-"] {
    text {
        fill: #fff;
    }
}

.close {
    color: $body-color;
    text-shadow: none;

    &:hover {
        color: $body-color;
    }
}

select.form-control {
    background: #{$input-bg} url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='%239b9ea2' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") no-repeat right 0.75rem center/18px 25px;
}

.datepicker-dropdown {
    &:after {
        border-bottom-color: $dropdown-bg;
    }

    &.datepicker-orient-top:after {
        border-top-color: $dropdown-bg;
    }
}

.bootstrap-timepicker-widget {
    .bootstrap-timepicker-hour,
    .bootstrap-timepicker-meridian,
    .bootstrap-timepicker-minute,
    .bootstrap-timepicker-second {
        background: transparent;
    }

    table td a {
        color: $body-color;
    }

    &.timepicker-orient-bottom {
        &:after {
            border-top-color: $dropdown-bg;
        }
    }
}

.select2-dropdown {
    background: $input-bg;
}

.select2-container--default {
    .select2-search--dropdown .select2-search__field {
        background: $input-bg;
        color: $input-color;
    }

    .select2-selection--multiple {
        background: $input-bg;
    }

    .select2-selection--single {
        background: $input-bg;

        .select2-selection__rendered {
            color: $input-color;
        }
    }
}

.irs--flat {
    .irs-line,
    .irs-max,
    .irs-min {
        background: darken($card-bg, 5%);
    }
}

.noUi-target {
    background: darken($card-bg, 5%);
    border-color: darken($card-bg, 6%);
    box-shadow: inset 0 1px 1px darken($card-bg, 8%), 0 3px 6px -5px lighten($card-bg, 5%);
}

.noUi-handle {
    border: 1px solid lighten($card-bg, 7%);
    background: lighten($card-bg, 5%);
    box-shadow: inset 0 0 1px lighten($card-bg, 8%), inset 0 1px 7px lighten($card-bg, 6%), 0 3px 6px -3px lighten($card-bg, 5%);
}

.noUi-tooltip {
    border-color: $border-color;
    background: $body-bg;
    color: $body-color;
}

.bootstrap-switch {
    .bootstrap-switch-handle-off,
    .bootstrap-switch-handle-on {
        &.bootstrap-switch-default {
            background: lighten($card-bg, 5%);
            color: $body-color;
        }
    }

    .bootstrap-switch-label {
        background: lighten($card-bg, 3%);
    }
}

.tt-menu {
    background: $dropdown-bg;
}

.uppy-DragDrop-arrow {
    fill: $body-color;
}

.sw-theme-arrows,
.sw-theme-circles,
.sw-theme-dots {
    .step-content,
    .sw-toolbar,
    > ul.step-anchor {
        background: transparent;
    }
}

td.highlight {
    background: transparent;
}

.apexcharts-grid-row,
polygon {
    fill: transparent;
}

.apexcharts-svg {
    line {
        stroke: $border-color;
    }

    polygon {
        stroke: lighten($card-bg, 3%);
    }
}

.highcharts-root {
    .highcharts-background {
        fill: transparent;
    }

    .highcharts-grid-line {
        stroke: transparentize($border-color, .5);
    }

    .highcharts-title {
        color: $body-color;
        fill: $body-color !important;
    }

    .highcharts-legend-item {
        text {
            fill: $body-color !important;
        }
    }
}

.user-card .user-about-block img {
    box-shadow: 0 0 0 5px $card-bg;
}

.q-view .content {
    background: $card-bg;
}

.hover-blk {
    background: darken($card-bg,4%);
}

.ticket-block {
    .ticket-type-icon {
        color: $body-color;
    }

    .col.border-right {
        border-color: $border-color !important;
    }

    .hd-body {
        border-color: $border-color;
    }
}

.hd-detail.bg-light,
.topic-name+.bg-light {
    background: darken($card-bg,2%) !important;
}

.card.background-pattern {
    .bg-white {
        background: darken($card-bg,4%) !important;
    }
}

.pc-header .pc-cart-menu {
    .drp-cart .cart-head,
    .drp-cart .cart-item.table-responsive {
        background: rgba(255,255,255,.05);
    }
}

.user-profile-list {
    table tr td {
        background: $card-bg;
    }
}

.user-profile {
    background: $card-bg;
}

.pc-container.sidebar-layouts {
    .pcoded-content {
        .sidebar-links {
            .nav-item,
            li {
                .nav-link,
                a {
                    &.active,
                    &:hover {
                        background: $body-bg;
                        color: $body-color;
                        border-color: $body-bg;
                    }
                }
            }
        }
    }
}

.bd-example {}

.highlight {
    background: transparent;

    pre code {
        color: $body-color;
    }
}

code[class*="language-"],
pre[class*="language-"] {
    color: $body-color;

    > code {
        background: $card-bg;
        box-shadow: -1px 0 0 0 #358ccb, 0 0 0 1px $border-color;
    }
}

.token.entity,
.token.operator,
.token.url,
.token.variable {
    background: transparent;
}

.swal2-popup {
    background: lighten($card-bg, 3%) !important;

    .swal2-title {
        color: lighten($body-color, 5%);
    }

    .swal2-content {
        color: $body-color;
    }

    .swal2-footer {
        border-top-color: $border-color;
    }
}

.pc-tab-control.wizard {
    > .steps {
        a {
            background: transparentize($card-bg, .5);
        }

        .current a {
            background: $card-bg;
        }
        .done a:hover{
            background: transparentize($card-bg, .5);
        }
    }
}
